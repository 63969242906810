import React, { useEffect, useMemo, useState } from "react";

import {
  Breadcrumb,
  Col,
  Row,
  Button,
  Table,
  Container,
  Dropdown,
  ProgressBar,
  Tabs,
  Tab,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-feather";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import bigInt from "big-integer";
import ButtonLoader from "../components/common/ButtonLoader";
import Navigation from "../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import DashboardStats from "../components/common/dashboardStats";
import DAUChart from "../components/charts/DAUCharts";

import http from "../utils/Api";
import {
  getAllRewardsAc,
  updateRewardStatus,
  updateRewardTransacitonId,
} from "../service/actions/rewardAction";

import Web3 from 'web3'
// import { toRpcSig } from "ethereumjs-util";

const Reward = () => {
  const [tranectionHash, setTransectonHash] = useState("");
  const [sideMenu, setSideMenu] = useState(true);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const dispatch = useDispatch();

  const [systemData, setSystemData] = useState({
    totalRewardGiven: 0,
    totalRewardUsdValue: 0,
    weeklyLimit: 0,
    weeklyRemainingLimit: 0,
    todayEarnings: 0,
    playerDailyLimit: 0,
    withdrawlLimit: 0,
    tokenLimit: 0,
  });

  // State for cancellation modal
  const [showModal, setShowModal] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [selectedRewardId, setSelectedRewardId] = useState(null);
  const [editedTransactionId, setEditedTransactionId] = useState("");
  const [isEditingTransactionId, setIsEditingTransactionId] = useState(false);
  const [editedRewardId, setEditedRewardId] = useState(null); // Track the currently edited reward ID

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(5);
  const [tab, setTab] = useState("web2");
  useEffect(() => {
    // Fetch system data when the component mounts
    getSystemData();
    // getDailyUser();
  }, []);

  useEffect(() => {
    console.log("running", "THE RUNNING");
    console.log("tab", tab);
    dispatch(getAllRewardsAc(currentPage, tab)).then((response) =>
      setLastPage(response)
    );
  }, [currentPage, tab]);

  const handleTab = (key) => {
    setTab(key);
    setCurrentPage(1);
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [dailyUsers, setDailyUsers] = useState([]);

  // JSX for pagination controls
  const pageNumbers = [];
  for (let i = 1; i <= lastPage; i++) {
    pageNumbers.push(i);
  }

  // Calculate the range of page numbers to display
  let startPage = Math.max(1, Math.min(currentPage - 4, lastPage - 9));
  let endPage = Math.min(startPage + 9, lastPage);

  // Adjust startPage if endPage is at the maximum limit
  startPage = Math.max(1, endPage - 9);

  // JSX for pagination controls with arrows and centered alignment
  const renderPageNumbers = (
    <ul className="pagination justify-content-center">
      {/* Previous page arrow */}
      <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
        <button
          onClick={() => paginate(currentPage - 1)}
          className="page-link"
          aria-label="Previous"
        >
          <span aria-hidden="true">&laquo;</span>
        </button>
      </li>

      {/* Render page numbers */}
      {Array.from(
        { length: endPage - startPage + 1 },
        (_, index) => startPage + index
      ).map((number) => (
        <li
          key={number}
          className={`page-item ${currentPage === number ? "active" : ""}`}
        >
          <button onClick={() => paginate(number)} className="page-link">
            {number}
          </button>
        </li>
      ))}

      {/* Next page arrow */}
      <li className={`page-item ${currentPage === lastPage ? "disabled" : ""}`}>
        <button
          onClick={() => paginate(currentPage + 1)}
          className="page-link"
          aria-label="Next"
        >
          <span aria-hidden="true">&raquo;</span>
        </button>
      </li>
    </ul>
  );

  const getSystemData = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const res = await http.get(
        "/api/admin/appStatistics",
        http.generateConfig(token)
      );
      if (res && res.result) {
        setSystemData({ ...res.result });
        // setEditedWeeklyLimit(res.result.weeklyLimit); // Initialize the edited value
        setLoading(false); // Mark loading as complete
      }
    } catch (error) {
      console.error("Error fetching system data:", error);
      setLoading(false); // Mark loading as complete even if there's an error
    }
  };

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  // const getDailyUser = async () => {
  //   const token = localStorage.getItem("accessToken");
  //   try{
  //   const res = await http.get("/api/admin/getDailyActiveUsers",
  //       http.generateConfig(token)

  //     );
  //     console.log(res)

  //     if(res ){
  //       setDailyUsers(res)
  //       setLoading(false)
  //     }
  //     console.log("daily users", dailyUsers)

  //   }catch (error){
  //     console.error("error fetching data", error)
  //     setLoading(false)

  //   }
  // }

  const allRewards = useSelector((state) => state.reward);
  const handleStatusUpdate = (rewardId, status) => {
    if (status === "cancelled") {
      // Set the selectedRewardId before showing the modal
      setSelectedRewardId(rewardId);
      setShowModal(true);
    } else {
      // For other statuses, proceed with the status update
      // (you can dispatch an action here)
      dispatch(updateRewardStatus({ id: rewardId, status , tab }));
    }
  };

  const saveCancellation = () => {
    // Handle the cancellation reason

    // Dispatch an action to update the reward status
    dispatch(
      updateRewardStatus({
        id: selectedRewardId,
        status: "cancelled",
        statusReason: cancellationReason,
        tab
      })
    );

    // Close the modal
    setShowModal(false);
  };

  const handleEditTransactionId = (rewardId, currentTransactionId) => {
    // Enable editing mode for Transaction ID
    setIsEditingTransactionId(true);
    setEditedRewardId(rewardId); // Set the currently edited reward ID
    // Set the edited value to the current Transaction ID
    setEditedTransactionId(currentTransactionId);
  };

  const handleSaveTransactionId = () => {
    // Find the index of the reward to update
    const rewardIndex = allRewards.findIndex(
      (reward) => reward.id === editedRewardId
    );

    if (rewardIndex !== -1) {
      // Create a copy of the array to avoid mutating the state directly
      const updatedRewards = [...allRewards];

      // Update the specific reward's transactionId
      updatedRewards[rewardIndex] = {
        ...updatedRewards[rewardIndex],
        transactionId: editedTransactionId,
      };

      const data = {
        out_id: updatedRewards[rewardIndex].id,
        player_id: updatedRewards[rewardIndex].Player.id,
        transaction_id: updatedRewards[rewardIndex].transactionId,
      };
      // Dispatch an action to update the specific reward
      dispatch(updateRewardTransacitonId(data)); // Assuming you have an updateReward action

      // Disable editing mode
      setIsEditingTransactionId(false);
      setEditedRewardId(null);
    }
  };

  // States for search inputs
  const [searchPlayer, setSearchPlayer] = useState("");
  const [searchRecievingChain, setSearchRecievingChain] = useState("");
  const [searchId, setSearchId] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  // Function to filter rewards based on search inputs
  const filteredRewards = allRewards.filter((reward) => {
    const playerInfo = reward.Player?.email || reward.Player?.mobile;
    const recievingChain = reward.receivingBlockchain || "";
    const status = reward.status?.toLowerCase() || "";

    const playerMatch =
      playerInfo?.toLowerCase().startsWith(searchPlayer.toLowerCase()) ||
      !searchPlayer; // Include all if searchPlayer is empty
    const recievingChainMatch =
      recievingChain
        .toLowerCase()
        .includes(searchRecievingChain.toLowerCase()) || !searchRecievingChain;
    const idMatch =
      reward.id?.toString().startsWith(searchId.toLowerCase()) || !searchId;
    const statusMatch =
      status.includes(searchStatus.toLowerCase()) || !searchStatus;

    // Combine the search conditions as needed
    return playerMatch && recievingChainMatch && idMatch && statusMatch;
  });

  function formatDate(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  }

  // const formatDecimal = (number) => {
  //   return parseFloat(number).toFixed(3);
  // };
  const copyToClipboard = (text) => {
    // Attempt to use navigator.clipboard API
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log("Text copied to clipboard:", text);
          // You may show a success message or perform additional actions
        })
        .catch((error) => {
          console.error("Unable to copy text to clipboard", error);
          // You may handle the error and show an error message
        });
    } else {
      // Fallback: Use a textarea element for manual copying
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      console.log("Text copied to clipboard:", text);
      // You may show a success message or perform additional actions
    }
  };

  const chartData = dailyUsers.map((player) => ({
    x: new Date(player.date).getTime(),
    y: player.dau,
  }));
  console.log(chartData);
  const chartOptions = {
    chart: {
      id: "line-chart",
    },
    xaxis: {
      type: "datetime",
    },
    stroke: {
      curve: "smooth",
      colors: ["#04542D"],
      width: 4,
      show: true,
      dashArray: 0,
    },
  };

  const chartSeries = [
    {
      name: "Daily Active Users",
      data: chartData,
    },
  ];
  const [showModal2, setShowModal2] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedReward, setSelectedReward] = useState(null);

  const handleButtonClick = (action, reward) => {
    setSelectedAction(action);
    setSelectedReward(reward);
    setShowConfirmationModal(true);
  };

  const handleConfirm = async () => {
    console.log(
      `${selectedAction} action confirmed for reward:`,
      selectedReward
    );
    let EarnTransactionHash;
    let UsdcTranactionHash;
    let errorMessage;
    let txReceipt;
    let walletAddress = selectedReward?.wallet;
    console.log("Wallet Address--->", walletAddress);
    setModalLoading(true);
    if (selectedAction === "USDC") {
      if (tab === "web2") {
        txReceipt = await SendUSD(
          "0xC2E7F1F6aDdA8f008BE1CAb4f0CA483d5f28C9F0",
          1000
        );
      } else if (tab === "web3") {
        console.log(selectedReward,'thakjsdnkjsaj')
        // let amount = parseInt(selectedReward?.amount) * 1000000;
       let  amount= Math.floor(selectedReward?.amount * 1000000).toString();

        console.log(selectedReward?.amount,'the alkndkjlasnlknajs')
        console.log(amount ,"<====>", walletAddress)
        console.log(amount.toString() ,"<====>", walletAddress)

        txReceipt = await SendUSDWeb3(walletAddress, amount.toString());
      }

      if (txReceipt && txReceipt.transactionHash) {
        // console.log('Transaction receipt:', txReceipt);
        UsdcTranactionHash = txReceipt.transactionHash;
        toast.success("Transaction successful!");
      } else {
        errorMessage = txReceipt; // Error message from SendUSD
        toast.error(`Transaction failed: ${errorMessage}`);
      }
    } else if (selectedAction === "$Earns") {
      const vestingContractAddress =
        "0x636c958df6e5a721a1edcc60f7954fb671e8897c"; // Replace with actual address
      const earnsAmount = "1000000000000000000"; // Replace with actual amount
      const xdcAmount = "500000000000000000"; // Replace with actual amount

      if (tab === "web2") {
        txReceipt = await SendEARNS(
          vestingContractAddress,
          earnsAmount,
          xdcAmount,
          selectedReward.wallet
        );
      } else if (tab === "web3") {
        const amount = http.Earn.utils.toWei(selectedReward?.amount, 'ether');

        txReceipt = await SendEARNSWeb3(walletAddress, amount);
      }
      console.log("ssss->", txReceipt.transactionHash);
      if (txReceipt && txReceipt.transactionHash) {
        EarnTransactionHash = txReceipt.transactionHash;
        toast.success("Transaction successful!");
      } else {
        errorMessage = txReceipt; // Error message from SendEARNS
        toast.error(`Transaction failed: ${errorMessage}`);
      }
    }
    if (txReceipt && txReceipt.transactionHash) {
      const token = localStorage.getItem("accessToken");
      const id = selectedReward.id;
      let data;
      if (EarnTransactionHash) {
        data = { id: id, EarnTransactionHash: EarnTransactionHash , fromWallet : txReceipt.from };
      } else {
        data = { id: id, UsdcTransactionHash: UsdcTranactionHash , fromWallet : txReceipt.from };
      }
      console.log("Data--->", data);
      const res = await http.post(
        "/api/admin/addTransactionHash",
        data,
        http.generateConfig(token)
      );
      const text = txReceipt.transactionHash;
      setTransectonHash(text);
      setShowModal2(true);
      setShowConfirmationModal(false);
    } else {
      setShowConfirmationModal(false);
    }
  };

  async function SendUSD(address, amount) {
    try {
      const senderAddress = http.USDC.utils.toChecksumAddress(
        "0x6A35f74Bc3785a1cb9E729f9a16D2840b2Dc18Ac"
      );
      const gasPrice = await http.USDC.eth.getGasPrice();
      const nonce = await http.USDC.eth.getTransactionCount(
        senderAddress,
        "pending"
      );
 
      const txObject = {
        to: http.usdcContactAddress,
        gasPrice: http.USDC.utils.toHex(gasPrice),
        gasLimit: http.USDC.utils.toHex(500000),
        nonce: http.USDC.utils.toHex(nonce),
        data: http.usdcContactInstance.methods
          .transferUSDC("0xC2E7F1F6aDdA8f008BE1CAb4f0CA483d5f28C9F0", 1000000)
          .encodeABI(),
      };

      const signedTx = await http.USDC.eth.accounts.signTransaction(
        txObject,
        "79db94410a39a288b7ecd2982a09bc1ec0b960ae1a7fba95c3a8dc7f7b02b01b"
      );
      const txReceipt = await http.USDC.eth.sendSignedTransaction(
        signedTx.rawTransaction
      );

      setModalLoading(false);
      return true; // Return the receipt to check for success
    } catch (err) {
      setModalLoading(false);
      console.error(err);
      return err.message; // Return the error message
    }
  }

  async function SendUSDWeb3(address, amount) {
    try {
      console.log(http.USDC, "THE USED");
      console.log(http.usdcContactInstance.methods, "THE USED 1");
      const senderAddress = http.USDC.utils.toChecksumAddress(
        "0x6A35f74Bc3785a1cb9E729f9a16D2840b2Dc18Ac"
      );
      const gasPrice = await http.USDC.eth.getGasPrice();
      const nonce = await http.USDC.eth.getTransactionCount(
        senderAddress,
        "pending"
      );
      console.log("---------APPRIVAL AFTER-----------------");

      const txObject = {
        to: http.usdcContactAddress,
        gasPrice: http.USDC.utils.toHex(gasPrice),
        gasLimit: http.USDC.utils.toHex(500000),
        nonce: http.USDC.utils.toHex(nonce),
        data: http.usdcContactInstance.methods
          .transferUSDC(address, amount)
          .encodeABI(),
      };

      const signedTx = await http.USDC.eth.accounts.signTransaction(
        txObject,
        "79db94410a39a288b7ecd2982a09bc1ec0b960ae1a7fba95c3a8dc7f7b02b01b"
      );
      const txReceipt = await http.USDC.eth.sendSignedTransaction(
        signedTx.rawTransaction
      );

      console.log("---------TRANSACTION-----------------");
      console.log("Transaction receipt:", txReceipt);
      setModalLoading(false);
      return txReceipt; // Return the receipt to check for success
    } catch (err) {
      setModalLoading(false);
      console.error(err);
      return err.message; // Return the error message
    }
  }

  async function SendEARNS() {
    try {
      const recipientAddress = "0xC2E7F1F6aDdA8f008BE1CAb4f0CA483d5f28C9F0";
      const amount = 1000000;
      const gasPrice = await http.Earn.eth.getGasPrice();
      const gasLimit = 500000;
      const nonce = await http.Earn.eth.getTransactionCount(
        "0x6A35f74Bc3785a1cb9E729f9a16D2840b2Dc18Ac",
        "pending"
      );

      const txData = http.earnContractInstance.methods
        .transferEarns(recipientAddress, amount)
        .encodeABI();

      const txParams = {
        to: "0xE3Ec3dc2034af97eE4E18eC95C7b5eBb893A2dd9",
        gasPrice: http.Earn.utils.toHex(gasPrice),
        gas: http.Earn.utils.toHex(gasLimit),
        nonce: http.Earn.utils.toHex(nonce),
        data: txData,
      };

      // Sign the transaction
      const signedTx = await http.Earn.eth.accounts.signTransaction(
        txParams,
        "79db94410a39a288b7ecd2982a09bc1ec0b960ae1a7fba95c3a8dc7f7b02b01b"
      );

      // Send the signed transaction
      const txReceipt = await http.Earn.eth.sendSignedTransaction(
        signedTx.rawTransaction
      );

      setModalLoading(false);
      console.log("Transaction successful:", txReceipt);
      return txReceipt;
    } catch (err) {
      setModalLoading(false);
      console.error(err);
      return err.message;
    }
  }

  async function SendEARNSWeb3(address, amountT) {
    try {
        const recipientAddress = address;
        const amount = amountT;
        
        const gasPrice = await http.Earn.eth.getGasPrice();
        const gasLimit = 500000;
        const nonce = await http.Earn.eth.getTransactionCount(
          "0x3c6004838F35929b3B6b5F00Bf554d79BF48C392",
          "latest"
        );
        
        const txParams = {
          to: http.earnContractAddress,
          gasPrice: http.Earn.utils.toHex(gasPrice),
          gas: http.Earn.utils.toHex(gasLimit),
          nonce: http.Earn.utils.toHex(nonce),
          data: http.earnContractInstance.methods
                .transferEarns(recipientAddress, amount)
                .encodeABI()
              };

        // Sign the transaction
        const signedTx = await http.Earn.eth.accounts.signTransaction(
            txParams,
            "a6882968bbd36fc3d7f6582f4af03da9344dfdeac8e18eeee0f79859a74e8036"
        );

        // Send the signed transaction
        const txReceipt = await http.Earn.eth.sendSignedTransaction(
            signedTx.rawTransaction
        );


        const recipientEARNSBalance = await http.earnContractInstance.methods.getEARNSBalance().call();
     
        // Assuming you're checking the XDC balance of the sender
        const senderXDCBalance = await http.earnContractInstance.methods.getXDCBalance().call();
     
        setModalLoading(false);
        return txReceipt;
    } catch (err) {
        setModalLoading(false);
        return err.message;
    }
}


  // async function depositUSDC(amount, fromAddress, privateKey) {
  //   try {
  //     // Convert amount to the appropriate format
  //     const amountInWei = web3.utils.toWei(amount.toString(), 'ether'); // Adjust unit if needed

  //     // Create the transaction object
  //     const txObject = {
  //       to: contractAddress,
  //       gas: 500000, // Adjust as needed
  //       gasPrice: await web3.eth.getGasPrice(),
  //       data: contract.methods.depositUSDC(amountInWei).encodeABI(),
  //     };

  //     // Sign the transaction
  //     const signedTx = await web3.eth.accounts.signTransaction(txObject, privateKey);

  //     // Send the signed transaction
  //     const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);

  //     console.log('Transaction receipt:', receipt);
  //     return receipt;
  //   } catch (error) {
  //     console.error('Error sending transaction:', error);
  //     throw error;
  //   }
  // }

  // const handleDeposite = async()=>{
  //   // Example usage
  // const amountToDeposit = 1000; // Replace with the amount you want to deposit
  // const userAddress = '0xUserAddressHere'; // Replace with the address from which the transaction will be sent
  // const userPrivateKey = '0xYourPrivateKeyHere'; // Replace with the private key (securely manage this)

  // depositUSDC(amountToDeposit, userAddress, userPrivateKey)
  //   .then(receipt => {
  //     console.log('Deposit successful:', receipt);
  //   })
  //   .catch(error => {
  //     console.error('Deposit failed:', error);
  //   });

  // }

  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />
      {/* End Navigation */}

      <div
        className={`main-content d-flex flex-column ${
          sideMenu ? "hide-sidemenu" : ""
        }`}
      >
        {/* Loader */}
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}
        {/* End Loader */}

        {/* Breadcrumb */}
        <div className="main-content-header">
          <Breadcrumb>
            <h1>Reward</h1>
          </Breadcrumb>
        </div>
        {/* End Breadcrumb */}

        <div
          className="max-width-container"
          style={{ width: "100%", maxWidth: "100%", margin: "0 auto" }}
        >
          <Row>
            <DashboardStats
              statistics={[
                {
                  title: "Total Rewarded",
                  count: systemData.totalRewardGiven.toFixed(3),
                },
                {
                  title: "Total Reward Dollar Value",
                  count: systemData.totalRewardUsdValue.toFixed(2),
                },
              ]}
            />
          </Row>

          <Row>
            <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className="m-2 mt-0 mb-2"
            >
              <p
                style={{
                  fontSize: "30px",
                  color: "#04542D",
                  fontWeight: 600,
                  paddingLeft: "5px",
                  padding: "20px",
                }}
              >
                {systemData.weeklyRemainingLimit} / {systemData.weeklyLimit}
              </p>
              <ProgressBar
                now={
                  (systemData.weeklyRemainingLimit / systemData.weeklyLimit) *
                  100
                }
                label={`${systemData.weeklyRemainingLimit}/${systemData.weeklyLimit}`}
                variant="success"
                className="mb-2"
                style={{ width: "50%", margin: "0 auto", height: "20px" }}
              />
              <p
                style={{
                  fontSize: "15px",
                  color: "#04542D",
                  fontWeight: 600,
                  paddingBottom: "5px",
                }}
              >
                (
                {systemData &&
                systemData.weeklyRemainingLimit &&
                systemData.weeklyLimit
                  ? Math.round(
                      (systemData.weeklyRemainingLimit /
                        systemData.weeklyLimit) *
                        100
                    )
                  : 0}
                % remaining)
              </p>
            </Col>
          </Row>

          <Row>
            <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className="m-2 mt-0 mb-2"
            >
              <DAUChart />
            </Col>
          </Row>

          <Row>
            <Container
              fluid
              className="p-2 mt-5"
              style={{ backgroundColor: "#fff", borderRadius: "15px" }}
            >
              <h5>Checkouts</h5>
              {renderPageNumbers}
              <Container className="mt-1 mb-1">
                <Tabs
                  activeKey={tab}
                  onSelect={handleTab}
                  id="uncontrolled-tab-example"
                  className="d-flex justify-content-center"
                >
                  <Tab eventKey="web2" title="Web2"></Tab>

                  <Tab eventKey="web3" title="Web3"></Tab>
                </Tabs>
              </Container>
              <div style={{ display: "flex", marginBottom: "15px" }}>
                {/* Search Inputs */}
                <Form.Control
                  type="text"
                  placeholder="Search ID..."
                  value={searchId}
                  onChange={(e) => setSearchId(e.target.value)}
                />
                <Form.Control
                  type="text"
                  placeholder="Search Player..."
                  value={searchPlayer}
                  onChange={(e) => setSearchPlayer(e.target.value)}
                />
                <Form.Control
                  type="text"
                  placeholder="Search Receiving Chain..."
                  value={searchRecievingChain}
                  onChange={(e) => setSearchRecievingChain(e.target.value)}
                />
                <Form.Control
                  as="select"
                  value={searchStatus}
                  onChange={(e) => setSearchStatus(e.target.value)}
                >
                  <option value="">Search Status...</option>
                  <option value="pending">Pending</option>
                  <option value="complete">Complete</option>
                  <option value="cancelled">Cancelled</option>
                </Form.Control>
              </div>

              {filteredRewards.length > 0 ? (
                <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                  <Table
                    hover
                    responsive
                    className="text-center"
                    style={{ margin: "0 auto" }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          id
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Player
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Total Reward Sent
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Total Reward USD
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Receiving Chain
                        </th>
                        {tab === "web3" ? (
                          <th
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            Amount Type
                          </th>
                        ) : null}
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Amount
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Created At
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Wallet Address
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          To Send
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Fraud Reason
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Reason
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Status
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Actions
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Transaction Id
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          Suspicious
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredRewards.map((reward, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                ? "orange"
                                : "grey",
                              fontWeight:
                                reward?.Player.warning ||
                                reward?.Player.isSuspicious
                                  ? "bold"
                                  : "normal",
                            }}
                          >
                            {reward?.id}
                          </td>
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                ? "orange"
                                : "grey",
                              fontWeight:
                                reward?.Player.warning ||
                                reward?.Player.isSuspicious
                                  ? "bold"
                                  : "normal",
                            }}
                          >
                            {reward?.Player.email
                              ? reward.Player.email
                              : reward.Player.mobile}
                          </td>
                          <td>{reward?.totalTokenSend}</td>
                          <td>{reward?.totalTokenSendUSD?.toFixed(3)}</td>
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                ? "orange"
                                : "grey",
                              fontWeight:
                                reward?.Player.warning ||
                                reward?.Player.isSuspicious
                                  ? "bold"
                                  : "normal",
                            }}
                          >
                            {reward?.receivingBlockchain}
                          </td>
                          {tab === "web2" ? (
                            <td
                              style={{
                                color: reward?.Player.warning
                                  ? "red"
                                  : reward?.Player.isSuspicious
                                  ? "orange"
                                  : "grey",
                                fontWeight:
                                  reward?.Player.warning ||
                                  reward?.Player?.isSuspicious
                                    ? "bold"
                                    : "normal",
                              }}
                            >
                              {reward.total}
                            </td>
                          ) : (
                            <>
                              <td
                                style={{
                                  color: reward?.Player.warning
                                    ? "red"
                                    : reward?.Player.isSuspicious
                                    ? "orange"
                                    : "grey",
                                  fontWeight:
                                    reward?.Player.warning ||
                                    reward?.Player?.isSuspicious
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                {reward.amountType}
                              </td>
                              <td
                                style={{
                                  color: reward?.Player.warning
                                    ? "red"
                                    : reward?.Player.isSuspicious
                                    ? "orange"
                                    : "grey",
                                  fontWeight:
                                    reward?.Player.warning ||
                                    reward?.Player?.isSuspicious
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                {reward.amount}
                              </td>
                            </>
                          )}
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player?.isSuspicious
                                ? "orange"
                                : "grey",
                              fontWeight:
                                reward?.Player.warning ||
                                reward?.Player.isSuspicious
                                  ? "bold"
                                  : "normal",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {formatDate(reward.createdAt)}
                          </td>
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                ? "orange"
                                : "grey",
                              fontWeight:
                                reward?.Player.warning ||
                                reward?.Player.isSuspicious
                                  ? "bold"
                                  : "normal",
                              padding: "5px 20px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: "150px", // Set the fixed width
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  color: reward?.Player.warning
                                    ? "red"
                                    : reward?.Player.isSuspicious
                                    ? "orange"
                                    : "grey",
                                  fontWeight:
                                    reward?.Player.warning ||
                                    reward?.Player.isSuspicious
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                {reward?.wallet}
                              </div>
                                <Icon.Copy
                                  onClick={() => copyToClipboard(reward.wallet)}
                                  color="blue"
                                  style={{ cursor: "pointer" }}
                                />
                            </div>
                          </td>
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                ? "orange"
                                : "grey",
                              fontWeight:
                                reward?.Player.warning ||
                                reward?.Player.isSuspicious
                                  ? "bold"
                                  : "normal",
                            }}
                          >
                            {reward.amountToSend}
                          </td>
                          <td>
                            {reward.Player?.playerFraudReasons &&
                            reward.Player?.playerFraudReasons?.length > 0 ? (
                              <>
                                {reward.Player?.playerFraudReasons[0]?.reason}
                              </>
                            ) : (
                              <>{"-"}</>
                            )}
                          </td>
                          <td>{reward?.statusReason || "-"}</td>
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                ? "orange"
                                : "grey",
                              fontWeight:
                                reward?.Player.warning ||
                                reward?.Player.isSuspicious
                                  ? "bold"
                                  : "normal",
                            }}
                          >
                            <Dropdown>
                              <Dropdown.Toggle
                                variant={
                                  reward.status === "pending"
                                    ? "warning"
                                    : reward.status === "confirmed"
                                    ? "success"
                                    : reward.status === "cancelled"
                                    ? "danger"
                                    // : reward.status === "fraud"
                                    // ? "danger"
                                    : "primary"
                                }
                                id={`dropdown-status-${index}`}
                              >
                                {reward.status || "N/A"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {reward.status !== "confirmed" && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleStatusUpdate(reward.id, "confirmed")
                                    }
                                  >
                                    Mark as Confirmed
                                  </Dropdown.Item>
                                )}
                                {reward.status !== "pending" && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleStatusUpdate(reward.id, "pending")
                                    }
                                  >
                                    Mark as Pending
                                  </Dropdown.Item>
                                )}
                                {reward.status !== "cancelled" && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleStatusUpdate(reward.id, "cancelled")
                                    }
                                  >
                                    Mark as Cancelled
                                  </Dropdown.Item>
                                )}
                                {/* {reward.status !== "fraud" && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleStatusUpdate(reward.id, "fraud")
                                    }
                                  >
                                    Mark as Fraud
                                  </Dropdown.Item>
                                )} */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="info"
                                id={`dropdown-actions-${index}`}
                              >
                                Actions
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {tab === "web3" ? (
                                  reward.amountType === "USDC" ? (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleButtonClick("USDC", reward)
                                      }
                                    >
                                      Send $USDC
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleButtonClick("$Earns", reward)
                                      }
                                    >
                                      Send $EARNS
                                    </Dropdown.Item>
                                  )
                                ) : (
                                  <>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleButtonClick("USDC", reward)
                                      }
                                    >
                                      Send $USDC
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleButtonClick("$Earns", reward)
                                      }
                                    >
                                      Send $EARNS
                                    </Dropdown.Item>
                                  </>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                          {tab === "web2" ? (
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    width: "150px",
                                    overflow: "scroll",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    color: reward?.Player.warning
                                      ? "red"
                                      : "grey",
                                    fontWeight:
                                      reward?.Player.warning ||
                                      reward?.Player.isSuspicious
                                        ? "bold"
                                        : "normal",
                                  }}
                                >
                                  {isEditingTransactionId &&
                                  editedRewardId === reward.id ? (
                                    <>
                                      <input
                                        type="text"
                                        value={editedTransactionId}
                                        onChange={(e) =>
                                          setEditedTransactionId(e.target.value)
                                        }
                                      />
                                      <Icon.Save
                                        variant="success"
                                        onClick={handleSaveTransactionId}
                                        style={{
                                          cursor: "pointer",
                                          transition: "color 0.3s",
                                        }}
                                        onMouseEnter={(e) =>
                                          (e.target.style.color = "blue")
                                        }
                                        onMouseLeave={(e) =>
                                          (e.target.style.color = "initial")
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {reward?.transaction_id ? (
                                        <span>{reward.transaction_id}</span>
                                      ) : (
                                        <span>-</span>
                                      )}
                                      <Icon.Copy
                                        onClick={() =>
                                          copyToClipboard(reward.transaction_id)
                                        }
                                        color="blue"
                                        style={{ cursor: "pointer" }}
                                      />
                                      <Icon.Edit
                                        variant="primary"
                                        onClick={() =>
                                          handleEditTransactionId(
                                            reward.id,
                                            reward.transaction_id
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          transition: "color 0.3s",
                                        }}
                                        onMouseEnter={(e) =>
                                          (e.target.style.color = "blue")
                                        }
                                        onMouseLeave={(e) =>
                                          (e.target.style.color = "initial")
                                        }
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            </td>
                          ) : (
                            <>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "150px",
                                      overflow: "scroll",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      color: reward?.Player.warning
                                        ? "red"
                                        : "grey",
                                      fontWeight:
                                        reward?.Player.warning ||
                                        reward?.Player.isSuspicious
                                          ? "bold"
                                          : "normal",
                                    }}
                                  >
                                    {isEditingTransactionId &&
                                    editedRewardId === reward.id ? (
                                      <>
                                        <input
                                          type="text"
                                          value={editedTransactionId}
                                          onChange={(e) =>
                                            setEditedTransactionId(
                                              e.target.value
                                            )
                                          }
                                        />
                                        <Icon.Save
                                          variant="success"
                                          onClick={handleSaveTransactionId}
                                          style={{
                                            cursor: "pointer",
                                            transition: "color 0.3s",
                                          }}
                                          onMouseEnter={(e) =>
                                            (e.target.style.color = "blue")
                                          }
                                          onMouseLeave={(e) =>
                                            (e.target.style.color = "initial")
                                          }
                                        />
                                      </>
                                    ) : (
                                      <>
                                        {reward?.transactionHash ? (
                                          <span>
                                            {reward.transactionHash}
                                          </span>
                                        ) : (
                                          <span>-</span>
                                        )}
                                        <Icon.Copy
                                          onClick={() =>
                                            copyToClipboard(
                                              reward.transactionHash
                                            )
                                          }
                                          color="blue"
                                          style={{ cursor: "pointer" }}
                                        />
                                        <Icon.Edit
                                          variant="primary"
                                          onClick={() =>
                                            handleEditTransactionId(
                                              reward.id,
                                              reward.transactionHash
                                            )
                                          }
                                          style={{
                                            cursor: "pointer",
                                            transition: "color 0.3s",
                                          }}
                                          onMouseEnter={(e) =>
                                            (e.target.style.color = "blue")
                                          }
                                          onMouseLeave={(e) =>
                                            (e.target.style.color = "initial")
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </>
                          )}
                          <td
                            style={{
                              color: reward?.Player.warning
                                ? "red"
                                : reward?.Player.isSuspicious
                                ? "orange"
                                : "grey",
                              fontWeight:
                                reward?.Player.warning ||
                                reward?.Player.isSuspicious
                                  ? "bold"
                                  : "normal",
                            }}
                          >
                            {reward?.Player.isSuspicious ? "True" : "False"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {showModal && (
                    <Modal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Provide Cancellation Reason</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form>
                          <Form.Group controlId="cancellationReason">
                            <Form.Label>Reason for Cancellation</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={cancellationReason}
                              onChange={(e) =>
                                setCancellationReason(e.target.value)
                              }
                              placeholder="Enter the reason for cancellation..."
                            />
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={() => setShowModal(false)}
                        >
                          Cancel
                        </Button>
                        <Button variant="primary" onClick={saveCancellation}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}
                </div>
              ) : (
                <>no reward</>
              )}
            </Container>
          </Row>
        </div>

        {/* Footer */}
        <div className="flex-grow-1"></div>
        <Footer />
        {/* End Footer */}

        {/* Confirmation Modal */}
        <Modal
          show={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to continue with the {selectedAction}{" "}
              action?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowConfirmationModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirm}>
              {modalLoading ? <ButtonLoader /> : "Confirm"}
              {/* Confirm */}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Second modal*/}
        <Modal show={showModal2} onHide={() => setShowModal2(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Key Retrieved</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Here is your hash key</h5>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#EAEAEA",
                padding: "5px",
              }}
            >
              <span
                style={{
                  width: "70%",
                  wordWrap: "break-word",
                  backgroundColor: "white",
                }}
              >
                {tranectionHash}
              </span>
              <CopyToClipboard text={tranectionHash}>
                <button
                  style={{
                    height: "39px",
                    padding: "8px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    marginLeft: "10px",
                    backgroundColor: "white",
                  }}
                >
                  <FaCopy style={{ fontSize: "20px" }} />
                </button>
              </CopyToClipboard>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal2(false)}>
              Cancel
            </Button>
            {/* <Button variant="primary" onClick={handleConfirm}>
              {
                modalLoading ? <ButtonLoader/> : "Confirm"
              }
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Reward;
// async function SendUSD(address, amount) {
//   try {
//     console.log("---------------ININ-=---------------")
//     // 0xC2E7F1F6aDdA8f008BE1CAb4f0CA483d5f28C9F0
//     // Fetching gas price
//     const gasPrice = await http.USDC.eth.getGasPrice();
//     const gasLimit = 500000;

//     // Approving the transfer of tokens
//    const ApprovalHash= await http.approvalContractInstance.methods.approve(
//       '0xe306dF7d3788F8D26B1b03E27AcA74ED1895E849', // Spender address
//       1000000 // Amount to approve
//     ).send({
//       from: '0x6A35f74Bc3785a1cb9E729f9a16D2840b2Dc18Ac', // Owner address
//       gasPrice: gasPrice,
//       gas: gasLimit
//     });

//     console.log('=-----------APPROVAL-================')
//     console.log(ApprovalHash,'THE APPROVAL HASD')
//     // Convert the recipient address to a checksum address
//     const recipientAddress = http.USDC.utils.toChecksumAddress('0xC2E7F1F6aDdA8f008BE1CAb4f0CA483d5f28C9F0');

//     console.log('=-----------After APPROVAL-================')
//     // Fetch the nonce
//     const nonce = await http.USDC.eth.getTransactionCount(
//       '0x6A35f74Bc3785a1cb9E729f9a16D2840b2Dc18A',
//       'pending'
//     );

//     // Create the transaction object
//     const txObject = {
//       to: http.usdcContactAddress, // The USDC contract address
//       gasPrice: http.USDC.utils.toHex(gasPrice),
//       gasLimit: http.USDC.utils.toHex(gasLimit),
//       nonce: http.USDC.utils.toHex(nonce),
//       data: http.usdcContactInstance.methods.transferUSDC(
//         recipientAddress, // The recipient address
//         1000000 // The amount to transfer
//       ).encodeABI(),
//     };

//     // Sign the transaction
//     const signedTx = await http.USDC.eth.accounts.signTransaction(
//       txObject,
//       '79db94410a39a288b7ecd2982a09bc1ec0b960ae1a7fba95c3a8dc7f7b02b01b' // Private key
//     );

//     // Send the signed transaction
//     const txReceipt = await http.USDC.eth.sendSignedTransaction(signedTx.rawTransaction);

//     console.log('Transaction receipt:', txReceipt);
//     return txReceipt; // Return the receipt to check for success
//   } catch (err) {
//     console.error(err);
//     return err.message; // Return the error message
//   }
// }
