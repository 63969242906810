import React, { useEffect, useState } from "react";
// import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import "./Navigation.css";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  Form,
  FormControl,
  Button,
  Image,
} from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { getSingleUserByIdAct } from "../../service/actions/userProfileAction";

// Default dark side menu
// If you want to activate the light sidebar, uncomment below & comment above component
import SideMenuLight from "./SideMenu/SideMenu";

// Logo image path
import Logo from "../../assets/img/logo192.png";
import ColorSwitch from "../common/colorSwitch";
// Profile & user image path
import profile from "../../assets/img/profile.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getGameStudio } from "../../service/actions/gameStudioActions";
import { toast } from "react-toastify";
const Navigation = (props) => {
  const [sideMenu, setSideMenu] = useState(false);
  const [term, setTerm] = useState("");
  const gameStudio = useSelector((state) => state.gameStudio);
  const userProfile = useSelector((state) => state.userProfile);
  const toggleClass = () => {
    setSideMenu(!sideMenu);
    props.onClick(sideMenu);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (term) {
      props.history.push("/search/");
    }
  };
  const onSideMenuHandler = (activeColor) => {
    setSideMenu({ menuColor: activeColor });
  };

  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("sessionToken");
    navigate('/')
    toast("logout successfully", "success");
  };
  const dispatch = useDispatch();
  // const userRole = JSON.parse(localStorage.getItem("userRoles"));
  // useEffect(() => {
  //   if (userRole.id === 1) {
  //     dispatch(getGameStudio());
  //   } else {
  //     dispatch(getSingleUserByIdAct());
  //   }
  // }, []);

  return (
    <div className="page-wrapper">
      <Navbar fixed="top" className="top-menu">
        <Link to="/home" className={`navbar-brand`}>
          {/* Large logo */}
          <Image
            src={Logo}
            alt="Logo"
            className="logo"
            style={{ height:"100%",width:"50px"}}
          />
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/* Burger menu */}
        <div
          className={`burger-menu ${sideMenu ? "" : "toggle-menu"}`}
          onClick={toggleClass}
        >
          <span className="top-bar"></span>
          <span className="middle-bar"></span>
          <span className="bottom-bar"></span>
        </div>
        {/* End Burger menu */}
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="justify-content-center"
        >
      <h2 style={{ fontFamily: 'YourFont', fontWeight: 'bold' }}>Admin Panel</h2>
          {/* <Nav className="ms-auto right-nav"></Nav> */}
        </Navbar.Collapse>

        <Button onClick={handleLogout}
         style={{ background: 'transparent', color: 'blue' }}>Logout</Button>
      </Navbar>

      {/* Side Menu File Path: src/components/Navigation/SideMenu/SideMenu.js */}
      {!false ? (
        <SideMenuLight sideMenu={sideMenu} />
      ) : (
        <SideMenuLight sideMenu={sideMenu} />
      )}

      {/*  */}

      {/* Side Menu File Path: src/components/Common/ColorSwitch.js */}
      {/* <ColorSwitch onClick={onSideMenuHandler} /> */}
    </div>
  );
};

export default Navigation;
