import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Image } from "react-bootstrap";
import * as Icon from "react-feather";
import { signin, signinGSWithGoogle, refreshToken } from "../service/actions/authActions";
// Logo image file path
import Logo from "../assets/img/earnscapeGreenlogo.png";
import phone from "../assets/img/phone.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signInWithGooglePopup } from "../services/firebase";
import googleIcon from "../assets/img/googleIcon.png";
import { getUsersPermission } from "../service/actions/usersActions";

import { toast } from "react-toastify";

const Login = (props) => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLoginHandler = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      dispatch(signin(formData)).then((data) => {
        const storedToken = localStorage.getItem("accessToken");
        if (storedToken) {
          toast.success("Login successful!", { position: "top-right" });
          navigate("/home");
        }

      });
    }
    setValidated(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const refreshToken1 = localStorage.getItem("refreshToken");
  
    if (token && refreshToken1) {
      
      navigate("/home");
    }
  }, []);
  

  return (
    <div className="auth-main-content">
      <div className="d-table">
        <div className="d-tablecell">
          <div>
            <Row className="align-items-center justify-content-center">
              <Col md={4}>
                <Image
                  src={Logo}
                  alt="Logo"
                  className="auth-logo1"
                  style={{ height: "100%", width: "50%" }}
                />
                <h6>Admin Portal</h6>
                <div className="form-content">
                  <h1 className="heading">Sign In</h1>

                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={onLoginHandler}
                    style={{ textAlign: "left" }}
                  >
                    <Form.Label>Email address</Form.Label>
                    <Form.Group className="mb-3">
                      <Form.Control
                        required
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <div
                      className="text-center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.6rem",
                      }}
                    >
                      <Button variant="primary" type="submit">
                        Log In
                      </Button>
                      {/* <Link to="forget-password" className="fp-link">
                        Forgot Password?
                      </Link> */}
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
